<template>
  <section>
    <div class="container margin-t">
      <vue-loaders
        v-if="loading"
        key="loading"
        name="ball-beat"
        color="orange"
        scale="2"
        class="d-block text-center"
      />
      <div class="row" v-else key="not-loading">
        <div class="row mb-5">
          <div
            v-for="tab in tabs"
            class="custom-btn text-center"
            :class="tab.col"
          >
            <b-button @click="getBlogs(null, null, tab.caption)"  class="blog-text-style">{{ tab.caption }}</b-button>
          </div>
        </div>
        <div
          class="col-lg-4 col-sm-12 col-12 margin-b cards"
          style="cursor: pointer"
          v-for="blog in blogs"
          :key="blog.id"
        >
          <router-link :to="`/blogs/${blog.id}`">
            <b-card
              :title="blog.title"
              img-src="https://picsum.photos/600/300/?image=25"
              img-alt="Image"
              img-top
            >
              <b-card-text>
                {{ blog.content }}
              </b-card-text>
              <div class="row">
                <div class="col-sm-8 col-8 display-avat-flex">
                  <img
                    src="https://picsum.photos/600/300/?image=25"
                    alt="Avatar"
                    class="avatar"
                  />
                  <div class="mx-2 mt-1">
                    <h6 class="name-style">{{ blog.author }}</h6>
                    <p class="m-0 stack-style">{{ blog.authorPosition }}</p>
                  </div>
                </div>
                <div class="col-sm-4 col-4 m-auto">
                  <h6 class="name-style m-0">{{ blogDate }}</h6>
                </div>
              </div>
            </b-card>
          </router-link>
        </div>
        <div v-if="moreBlogs == true" class="row justify-content-center mt-5">
          <div class="col-md-3 col-12 text-center">
            <button class="shadow show-more-btn" @click="hasMoreBlogs">Show More</button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </section>
</template>

<script>
import Footer from "../Footer.vue";
import moment from "moment";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      loading: true,
      vacancies: [],
      blogs: [],
      moreBlogs: null,
      tabs: [
        { caption: "FRONTEND", col: "col-lg-2 col-sm-6 col-12" },
        { caption: "BACKEND", col: "col-lg-2 col-sm-6 col-12" },
        { caption: "PHP", col: "col-lg-1 col-sm-6 col-12" },
        { caption: "UX/UI", col: "col-lg-1 col-sm-6 col-12" },
        { caption: "MOBILE DEVELOPMENT", col: "col-lg-3 col-sm-6 col-12" },
        { caption: "WEB DEVELOPMENT", col: "col-lg-3 col-sm-6 col-12" },
      ],
      number: -1,
      pageSize: 6,
      tagName: ''
    };
  },
  async mounted() {
    this.getBlogs(null, null, 'FRONTEND');
  },
  computed: {
    blogDate(blog) {
      return moment(blog.createdDate).format("DD MMMM YYYY");
    },
  },
  methods: {
    hasMoreBlogs() {
      let data = this.number +=1
      let page = this.pageSize += 6
      this.getBlogs(data, page, this.tagName)
    },
    async getBlogs(num, page, tag) {
      this.tagName = tag
      let data = await this.$axios.get("/Blog/blog-items", {
        params: { PageNumber: num, PageSize: page},
      });
      let filteredBlogs = data.data.items.filter(items => items.tags.includes(tag))
      this.blogs = filteredBlogs;
      this.moreBlogs = data.data.hasMore
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.custom-btn button,
input[type="submit"],
input[type="reset"] {
  box-shadow: unset !important;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit !important;
}
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.show-more-btn {
  background: #fafafa !important;
  border: 1px solid #f48a17 !important;
  border-radius: 25px !important;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  padding: 12px 27px;
  color: #f48a17 !important;
}
.show-more-btn > a {
  color: #f48a17 !important;
}
.custom-btn .btn-check:checked {
  box-shadow: unset;
}
.custom-btn .btn-secondary:hover,
.custom-btn .btn-secondary:active,
.custom-btn .btn-secondary:focus {
  color: rgba(28, 62, 95, 0.95);
}
.cards .card {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border: unset;
}
.display-avat-flex {
  display: flex;
}
.cards .card-title {
  font-size: 22px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: rgba(28, 62, 95, 0.95);
}
.cards .card-body {
  padding: 2rem 1rem;
}
.cards .card-text {
  font-size: 14px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  color: rgba(28, 62, 95, 0.8);
  letter-spacing: 0.04em;
  line-height: 163.15%;
  display: -webkit-box;
  max-width: 90%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.cards .card-img-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.blog-text-style {
  color: #f48a17;
  font-weight: 400 !important;
  font-size: 24px !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
}
.margin-t {
  margin-top: 430px;
}
.margin-b {
  margin-bottom: 70px;
  padding-left: 35px;
  padding-right: 35px;
}
.name-style {
  white-space: nowrap;
  margin: 8px 0 0 0;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.04em;
  color: rgba(28, 62, 95, 0.8);
}
.stack-style {
  white-space: nowrap;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.04em;
  color: rgba(28, 62, 95, 0.8);
}

@media (min-width: 320px) and (max-width: 1200px) {
  .margin-b {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
