<template>
  <section>
    <div class="container margin-t">
      <div class="padding-design-div">
        <h3 class="main-heading-style">{{ blogDetail.title }}</h3>
        <div class="row mt-4">
          <div class="col-sm-6 col-8 display-avat-flex">
            <img
              src="https://picsum.photos/600/300/?image=25"
              alt="Avatar"
              class="avatar"
            />
            <div class="mx-4">
              <h6 class="name-style">{{ blogDetail.author }}</h6>
              <p class="m-0 stack-style">{{ blogDetail.authorPosition }}</p>
            </div>
          </div>
          <div class="col-sm-6 col-4 m-auto">
            <h6 class="name-style m-0">{{ blogDate }}</h6>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <img
          src="https://picsum.photos/600/300/?image=25"
          class="img-fluid width-fullscreen"
        />
      </div>
      <div class="padding-design-div mt-5">
        <p class="blog-content">{{ blogDetail.content }}</p>
        <div class="row mt-4">
          <div class="col-sm-6 col-12 display-avat-flex">
            <img
              src="https://picsum.photos/600/300/?image=25"
              alt="Avatar"
              class="avatar"
            />
            <div class="mx-4">
              <h6 class="written-style">WRITTEN BY</h6>
              <h6 class="name-style">{{ blogDetail.author }}</h6>
              <p class="m-0 stack-style">{{ blogDetail.authorPosition }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </section>
</template>

<script>
import Footer from "../Footer.vue";
import moment from "moment";
export default {
  props: { blogDetail: Object },
  components: {
    Footer,
  },
  data() {
    return {};
  },
  async mounted() {},
  computed: {
    blogDate(blog) {
      return moment(blog.createdDate).format("DD MMMM YYYY");
    },
  },
  methods: {},
};
</script>

<style scoped>
.margin-t {
  margin-top: 430px;
}
.main-heading-style {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 49px;
  letter-spacing: 0.04em;
  color: rgba(28, 62, 95, 0.95);
}
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.padding-design-div {
  padding: 0 300px;
}
.display-avat-flex {
  display: flex;
}
.width-fullscreen {
  width: 900px;
}
.written-style{
   white-space: nowrap;
  margin: 8px 0 0 0;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.04em;
  color: rgba(28, 62, 95, 0.8);
}
.name-style {
  white-space: nowrap;
  margin: 8px 0 0 0;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.04em;
  color: rgba(28, 62, 95, 0.8);
}
.stack-style {
  white-space: nowrap;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.04em;
  color: rgba(28, 62, 95, 0.8);
}
.blog-content {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 163.15%;
  text-align: justify;
  letter-spacing: 0.07em;
  color: rgba(28, 62, 95, 0.95);
}
@media (min-width: 320px) and (max-width: 1200px) {
  .padding-design-div {
    padding: unset;
  }
  .width-fullscreen {
    max-width: 100%;
  }
}
</style>
