<template>
  <section>
    <div class="container">
      <span class="anchor" id="test"></span>
      <p class="services-paragraph">Services list</p>
      <h1 class="services-heading">Explore what we offer</h1>
      <ServiceListRight
        class="margin-t d-md-none d-flex"
        title="Web App Development"
        description="Our web app development services focus on creating high-quality, customized web-based applications that meet the specific needs of our clients. We understand that every business is unique, and therefore, requires a tailored solution to achieve its objectives. Our web app development team has the expertise to design, build, and deploy applications that are visually appealing, intuitive, and user-friendly."
        :src="WebAppImage"
      />
      <ServiceListLeft
        class="margin-t d-md-flex d-none"
        title="Web App Development"
        description="Our web app development services focus on creating high-quality, customized web-based applications that meet the specific needs of our clients. We understand that every business is unique, and therefore, requires a tailored solution to achieve its objectives. Our web app development team has the expertise to design, build, and deploy applications that are visually appealing, intuitive, and user-friendly."
        :src="WebAppImage"
      />
      <ServiceListRight
        class="margin-t-1"
        title="Mobile App Development"
        description="We understand that mobile devices have become an essential part of our daily lives, and therefore, our mobile app development team has the expertise to create applications that are intuitive, user-friendly, and visually appealing. Our mobile app development process begins with a detailed analysis of your requirements and objectives. We work closely with you to understand your business needs, target audience, and the features and functionalities you require. We then use this information to develop a comprehensive plan and create wireframes and prototypes that bring your vision to life."
        :src="MobileImage"
      />
      <ServiceListRight
        class="margin-t-1 d-md-none d-flex"
        title="Consultancy"
        description="Our consultancy service starts with a thorough analysis of your business objectives, existing software architecture, and IT infrastructure. Our team of experienced consultants has a deep understanding of the latest technologies and trends in software development. We provide guidance on best practices for software development, help you evaluate technology options and make informed decisions, and assist with project management to ensure successful outcomes. Our consultancy service is designed to help you achieve your business goals by providing you with the expertise and support you need to succeed in today's competitive landscape."
        :src="ConsultImage"
      />
      <ServiceListLeft
        class="margin-t-1 d-md-flex d-none"
        title="Consultancy"
        description="Our consultancy service starts with a thorough analysis of your business objectives, existing software architecture, and IT infrastructure. Our team of experienced consultants has a deep understanding of the latest technologies and trends in software development. We provide guidance on best practices for software development, help you evaluate technology options and make informed decisions, and assist with project management to ensure successful outcomes. Our consultancy service is designed to help you achieve your business goals by providing you with the expertise and support you need to succeed in today's competitive landscape."
        :src="ConsultImage"
      />
    </div>
  </section>
</template>

<script>
import WebAppImage from "@/assets/web-dev-service.svg";
import ConsultImage from "@/assets/consult.svg";
import MobileImage from "@/assets/mobile-dev.svg";
import ServiceListRight from "@/components/ServicesComponents/ServiceListRight.vue";
import ServiceListLeft from "@/components/ServicesComponents/ServiceListLeft.vue";
export default {
  components: {
    ServiceListLeft,
    ServiceListRight,
  },
  data() {
    return { WebAppImage, ConsultImage, MobileImage };
  },
};
</script>

<style scoped>
.margin-t {
  margin-top: 120px;
}
.margin-t-1 {
  margin-top: 250px;
}

.anchor {
  display: block !important;
  height: 92px;
  margin-top: -92px !important;
  visibility: hidden !important;
}

.services-paragraph {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 163.15%;
  letter-spacing: 0.02em;
  color: #f48a17;
  text-align: center;
}
.services-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 59px;
  text-align: center;
  letter-spacing: 0.02em;
  color: rgba(28, 62, 95, 0.95);
}
@media only screen and (max-width: 767px) {
  .margin-t-1 {
    margin-top: 100px;
  }
}
</style>
