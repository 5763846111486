import { render, staticRenderFns } from "./OpenPositions.vue?vue&type=template&id=13e8e55f&scoped=true&"
import script from "./OpenPositions.vue?vue&type=script&lang=js&"
export * from "./OpenPositions.vue?vue&type=script&lang=js&"
import style0 from "./OpenPositions.vue?vue&type=style&index=0&id=13e8e55f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13e8e55f",
  null
  
)

export default component.exports