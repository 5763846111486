<template>
  <div>
    <Navbar color="#FAFAFA" :logo="logo" />
    <BlogsHeader title="Blog" tagline="IT development blog articals." />
    <BlogDetailView :blogDetail="blogDetail" />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import BlogsHeader from "@/components/Blogs/BlogsHeader.vue";
import BlogDetailView from "@/components/Blogs/BlogDetailView.vue";
import logo from "../assets/logo-white.svg";
export default {
  components: { Navbar, BlogsHeader, BlogDetailView },
  data() {
    return {
      blogDetail: {},
      logo,
    };
  },
  mounted() {
    this.getBlogDetail();
  },
  methods: {
    async getBlogDetail() {
      let data = await this.$axios.get("/Blog/blog-items");
      this.blogDetail = data.data.items.find(
        (element) => this.$route.params.id == element.id
      );
    },
  },
};
</script>

<style scoped>
.nav-colored {
  background-color: rgba(234, 234, 234, 0.773) !important;
}
.nav-transparent {
  background-color: transparent;
}
</style>
