<template>
  <div id="app">
    <router-view style="height: 100vh" />
    <Cookies id="cookies" />
  </div>
</template>

<script>
// @ is an alias to /src
import Cookies from "@/components/Cookies.vue";

export default {
  components: {
    Cookies,
  },
};
</script>

<style>
body, html {
  height: 100%;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600&family=Nunito:wght@200;300;400;500&display=swap");
#app {
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#cookies {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  background: #fafafa;
  box-shadow: 0px -14px 35px rgba(122, 122, 122, 0.35);
}
a {
  text-decoration: none !important;
}
.nav-item a {
  font-size: 17px;
  line-height: 163.15%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none !important;
}
.nav-item a:hover {
  color: #f48a17 !important;
}
</style>
