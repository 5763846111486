<template>
  <div class="container-fluid margin-t">
    <span class="anchor" id="test"></span>
    <p class="project-paragraph">Work completed</p>
    <h1 class="project-heading margin-b">Our projects showcase</h1>

    <div class="row justify-content-between carousel-none">
      <carousel
        stagePadding="110"
        :responsive="{
          0: { items: 1, nav: true },
          600: { items: 2, nav: true },
          1000: { items: 3, nav: true },
        }"
        :rewind="false"
        :dots="true"
      >
        <div
          class="mb-md-0 mb-5 py-5 px-lg-3 px-3"
          v-for="(project, index) in projectsData"
          :key="project.id"
        >
          <ProjectCard
            :src="image[index]"
            :title="project.title"
            :category="project.category"
            :tagline="project.tagline"
            :description="project.description"
            :techStack="project.techStack"
          />
        </div>
      </carousel>
    </div>
    <div class="row justify-content-between carousel-mobile-none">
      <carousel
        :startPosition="0"
        :responsive="{
          0: { items: 1, nav: true },
          1000: { items: 2, nav: true },
        }"
        :rewind="false"
        :dots="true"
      >
        <div
          class="mb-md-0 mb-5 pt-5 pb-3 px-lg-3 px-3"
          v-for="(project, index) in projectsData"
          :key="project.id"
        >
          <ProjectCard
            :src="image[index]"
            :title="project.title"
            :category="project.category"
            :tagline="project.tagline"
            :description="project.description"
            :techStack="project.techStack"
          />
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
// import projectsData from "./projects.json";
import ProjectCard from "@/components/ProjectsComponents/ProjectCard.vue";
import image1 from "@/assets/Desktop-Main-Card.webp";
import image2 from "@/assets/IndigoHealth.webp";
import image3 from "@/assets/WarehouseMainCard.webp";
import image4 from "@/assets/MobileMainCard.webp";
import image5 from "@/assets/MatchSpace.webp";
import carousel from "vue-owl-carousel";
import azureLogo from "@/assets/technologies/azure_logo.svg";
import xamarinLogo from "@/assets/technologies/xamarin_logo.svg";
import vuejsLogo from "@/assets/technologies/vuejs_logo.svg";
import netCoreLogo from "@/assets/technologies/net_core_logo.svg";
import netMauiLogo from "@/assets/technologies/net_maui_logo.png";
import flutterLogo from "@/assets/technologies/flutter_logo.svg";
import wpfLogo from "@/assets/technologies/WPF_logo.png";
import angularLogo from "@/assets/technologies/angular_logo.svg";
import msqsqlLogo from "@/assets/technologies/mssql_logo.svg";
import reactLogo from "@/assets/technologies/react_logo.svg";
import reactNativeLogo from "@/assets/technologies/react_native_logo.svg";

export default {
  name: "Projects",
  components: {
    ProjectCard,
    carousel,
  },
  data() {
    return {
      image: [image1, image5, image2, image3, image4],
      id: 1,
      project: {},
      clicked: 0,
      projectsData: [
        {
          category: "Health",
          title: "Medical Billing SaaS Platform",
          description:
            "The platform handles complicated rules and regulations in an easy, user-friendly service that includes management of contractor payrolls, and scheduling of payment to payers and clearinghouses.",
          techStack: [vuejsLogo, netCoreLogo, azureLogo],
        },
        {
          category: "Lifestyle, Fitness",
          title: "Matchspace tennis app and coach dashboard",
          description: "This is next generation software subscription service for tennis clubs. The free members app builds community and helps to connect members in a tennis club with its easy to use leagues, member directory chat, club feed and trophy rewards.",
          techStack: [reactNativeLogo, reactLogo, azureLogo],
        },
        {
          category: "Mental Health Care",
          title: "Indigo Health Patient App",
          description:
            "The Indigo Health app is the app that helps psychiatrists and other mental health professionals treat their patients. In particular, Indigo Health helps psychiatrists collect and visualize information about their patients’ mental health.",
          techStack: [netMauiLogo, netCoreLogo],
        },
        {
          category: "Warehousing",
          title: "Warehouse Management System",
          description:
            "The system is made to help users improve the efficiency of tracking inventory and supplies as they move through a warehouse or distribution center.",
          techStack: [angularLogo, flutterLogo, msqsqlLogo, wpfLogo],
        },
        {
          category: "Lifestyle",
          title: "Renting SaaS Platform",
          description:
            "On-line Business Platform and a great showcase for Fleet Owners to meet and interact with Drivers regardless of where they are. Built-in chat allows staying in touch with owners and renters with expedition. The scheduling payment system made payments on time.",
          techStack: [xamarinLogo, netCoreLogo],
        },
      ],
    };
  },
  mounted() {
    this.styleCarouselButtons();
  },
  methods: {
    styleCarouselButtons() {
      const nav = document.getElementsByClassName("owl-nav");

      nav[0].classList.add("row");
      nav[0].classList.add("m-5");
      nav[0].classList.add("justify-content-end");
      nav[0].childNodes[0].classList.add("col-1");

      nav[0].childNodes[0].classList.remove("owl-prev");
      nav[0].childNodes[1].classList.add("col-1");
      nav[0].childNodes[1].classList.remove("owl-next");
    },
  },
};
</script>

<style>
.owl-theme .owl-nav{
 display: none;
}
.owl-dots  .active span{
  background: #f48a17 !important;
}
</style>

<style scoped>
.anchor {
  display: block !important;
  height: 92px;
  margin-top: -92px !important;
  visibility: hidden !important;
}

.margin-b {
  margin-bottom: 120px;
}
.margin-t {
  margin-top: 220px !important;
}

.project-paragraph {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 163.15%;
  letter-spacing: 0.02em;
  color: #f48a17;
  text-align: center;
}
.project-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 52px;
  line-height: 59px;
  text-align: center;
  letter-spacing: 0.02em;
  color: rgba(28, 62, 95, 0.95);
}

.carousel-none div{
  padding-right: unset;
  padding-left: unset;
}

@media only screen and (max-width: 767px) {
  .margin-t {
    margin-top: 100px !important;
  }
}

@media(max-width: 1000px) {
  .carousel-none {
    display: none;
  }
}
@media(min-width: 1000px) {
  .carousel-mobile-none {
    display: none;
  }
}
</style>
