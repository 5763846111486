<template>
  <div>
    <Navbar color="#FAFAFA" :logo="logo" />
    <BlogsHeader title="Blog" tagline="IT development blog articals." />
    <AllBlogs />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import logo from "../assets/logo-white.svg";
import BlogsHeader from "@/components/Blogs/BlogsHeader.vue";
import AllBlogs from "@/components/Blogs/AllBlogs.vue";
export default {
  components: {
    AllBlogs,
    Navbar,
    BlogsHeader
  },
  data() {
    return {
      logo,
    };
  },
};
</script>
