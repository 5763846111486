var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid margin-t"},[_c('span',{staticClass:"anchor",attrs:{"id":"test"}}),_c('p',{staticClass:"project-paragraph"},[_vm._v("Work completed")]),_c('h1',{staticClass:"project-heading margin-b"},[_vm._v("Our projects showcase")]),_c('div',{staticClass:"row justify-content-between carousel-none"},[_c('carousel',{attrs:{"stagePadding":"110","responsive":{
        0: { items: 1, nav: true },
        600: { items: 2, nav: true },
        1000: { items: 3, nav: true },
      },"rewind":false,"dots":true}},_vm._l((_vm.projectsData),function(project,index){return _c('div',{key:project.id,staticClass:"mb-md-0 mb-5 py-5 px-lg-3 px-3"},[_c('ProjectCard',{attrs:{"src":_vm.image[index],"title":project.title,"category":project.category,"tagline":project.tagline,"description":project.description,"techStack":project.techStack}})],1)}),0)],1),_c('div',{staticClass:"row justify-content-between carousel-mobile-none"},[_c('carousel',{attrs:{"startPosition":0,"responsive":{
        0: { items: 1, nav: true },
        1000: { items: 2, nav: true },
      },"rewind":false,"dots":true}},_vm._l((_vm.projectsData),function(project,index){return _c('div',{key:project.id,staticClass:"mb-md-0 mb-5 pt-5 pb-3 px-lg-3 px-3"},[_c('ProjectCard',{attrs:{"src":_vm.image[index],"title":project.title,"category":project.category,"tagline":project.tagline,"description":project.description,"techStack":project.techStack}})],1)}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }