<template>
  <div>
    <div class="card shadow" id="project-card">
      <img class="card-img-top" alt="Card image cap" :src="src" />
      <div class="card-body p-4">
        <p class="sub-heading mt-4">{{ category }}</p>
        <h5 class="card-title mb-4">{{ title }}</h5>
        <p class="card-text">{{ description }}</p>
        <p class="text mt-4">TECHNOLOGIES</p>
        <div class="col-12 icon-images">
          <img
            :src="technology"
            v-for="technology in techStack"
            :key="technology.id"
            style="margin-right: 10px; margin-top: 10px"
            :height="45"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectCard",
  props: { src: String, title: String, category: String, tagline: String, description: String, techStack: Array },
};
</script>
<style scoped>
.card-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 158.2%;
  letter-spacing: 0.04em;
  color: rgba(28, 62, 95, 0.95);
  /* height: 150px;
  max-height: 155px; */
}
.card {
  background: #fafafa !important;
  border-radius: 8px !important;
  border: none;
  height: 1024px;
}
.card-img-top {
  border-radius: 8px 8px 0 0 !important;
}
.card-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 30px;
  letter-spacing: 0.03em;
  color: rgba(28, 62, 95, 0.95);
}
.sub-heading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 174.2%;
  letter-spacing: 0.06em;
  color: #f48a17;
}
.icon-images img{
  width: unset !important;
  display: inline-flex !important;
}
@media only screen and (max-width: 320px) {
  .card-text {
    font-weight: 500;
    font-size: 17px;
    line-height: 158.2%;
    letter-spacing: 0.04em;
    color: rgba(28, 62, 95, 0.95);
    height: 135px;
    max-height: 135px;
  }
}
@media(max-width: 1000px) {
  .card {
    height: unset;
  }
}
</style>
